import * as R from 'ramda'
import {graphql} from 'gatsby'
import React from 'react'

import SectionComponent from 'components/UI/SectionComponent'
import SEO from 'components/seo'
import useScrollIntoView from 'hooks/useScrollIntoView'

const Page = ({data, location, pageContext}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const localizedContent = R.pathOr(null, ['pageData'], data)
  const sections = R.pathOr(null, ['sections'], localizedContent)
  const metaTitle = R.pathOr(null, ['metaTitle'], localizedContent)
  const metaDescription = R.pathOr(null, ['metaDescription'], localizedContent)
  const siteMetaData = R.pathOr('', ['siteMetaData'], data)
  const genericData = R.pathOr('', ['contentfulGenericData'], data)
  const {hash} = location

  useScrollIntoView(hash)

  const pageMetaData = {
    metaTitle,
    metaDescription,
    siteMetaData,
    genericData,
  }

  return (
    <div>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <SectionComponent sections={sections} />
    </div>
  )
}

export default Page

export const query = graphql`
  query templatePage($nodeLocale: String, $slug: String, $pageCountry: String) {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
    pageData: contentfulPage(
      slug: {eq: $slug}
      countries: {elemMatch: {technicalName: {eq: $pageCountry}}}
      node_locale: {eq: "zh-Hans-CN"}
    ) {
      ...PageFields
    }
    contentfulCountry(
      node_locale: {eq: "zh-Hans-CN"}
      technicalName: {eq: $pageCountry}
    ) {
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
